import payload_plugin_oHE3b5bRuZ from "/opt/render/project/src/node_modules/.pnpm/nuxt-vuefire@1.0.2_@firebase+app-types@0.9.2_firebase-admin@11.11.1_firebase-functions@4.7.0__7snokqdjsl2hjrgriqulipc3dq/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.mjs";
import revive_payload_client_bdma4Mbj8Z from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.10.1_@opentelemetry+api@1.6.0_@types+node@20.11.17_eslint@8.56.0_typescript@5.3.3_vite@5.2.6/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_4yWaAdP3j3 from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.10.1_@opentelemetry+api@1.6.0_@types+node@20.11.17_eslint@8.56.0_typescript@5.3.3_vite@5.2.6/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_bxhbkZmPIq from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.10.1_@opentelemetry+api@1.6.0_@types+node@20.11.17_eslint@8.56.0_typescript@5.3.3_vite@5.2.6/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_FCiZMVb7ap from "/opt/render/project/src/node_modules/.pnpm/nuxt-site-config@2.2.9_@nuxt+devtools@1.0.8_@vue+compiler-core@3.4.18_nuxt@3.10.1_postcss@8.4_czbhoml2at4elybd2cms4e2oc4/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_gIEhzyvVqF from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.10.1_@opentelemetry+api@1.6.0_@types+node@20.11.17_eslint@8.56.0_typescript@5.3.3_vite@5.2.6/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_UpjfNsh6RI from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.10.1_@opentelemetry+api@1.6.0_@types+node@20.11.17_eslint@8.56.0_typescript@5.3.3_vite@5.2.6/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/opt/render/project/src/apps/refresh/.nuxt/components.plugin.mjs";
import prefetch_client_a7HYD0Il3r from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.10.1_@opentelemetry+api@1.6.0_@types+node@20.11.17_eslint@8.56.0_typescript@5.3.3_vite@5.2.6/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_A1wZownV0O from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+device@3.1.1_rollup@3.29.4/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_cL7x1BY8qJ from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+algolia@1.10.1_rollup@3.29.4_vue@3.4.18/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import i18n_aSpoB5TSHX from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+i18n@8.1.0_rollup@3.29.4_vue@3.4.18/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_client_YgYPmVaIFk from "/opt/render/project/src/node_modules/.pnpm/nuxt-vuefire@1.0.2_@firebase+app-types@0.9.2_firebase-admin@11.11.1_firebase-functions@4.7.0__7snokqdjsl2hjrgriqulipc3dq/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.mjs";
import vuefire_plugin_8cq4FnzXKb from "/opt/render/project/src/apps/refresh/.nuxt/vuefire-plugin.mjs";
import chunk_reload_client_tS8YRTYF39 from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.10.1_@opentelemetry+api@1.6.0_@types+node@20.11.17_eslint@8.56.0_typescript@5.3.3_vite@5.2.6/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import contentful_bOzdIRmqaX from "/opt/render/project/src/apps/refresh/plugins/contentful.js";
import rollbar_XnPIYyOzed from "/opt/render/project/src/apps/refresh/plugins/rollbar.ts";
import socialShare_gA6PfhsR4E from "/opt/render/project/src/apps/refresh/plugins/socialShare.js";
export default [
  payload_plugin_oHE3b5bRuZ,
  revive_payload_client_bdma4Mbj8Z,
  unhead_4yWaAdP3j3,
  router_bxhbkZmPIq,
  _0_siteConfig_FCiZMVb7ap,
  payload_client_gIEhzyvVqF,
  check_outdated_build_client_UpjfNsh6RI,
  components_plugin_KR1HBZs4kY,
  prefetch_client_a7HYD0Il3r,
  plugin_A1wZownV0O,
  plugin_cL7x1BY8qJ,
  i18n_aSpoB5TSHX,
  plugin_client_YgYPmVaIFk,
  vuefire_plugin_8cq4FnzXKb,
  chunk_reload_client_tS8YRTYF39,
  contentful_bOzdIRmqaX,
  rollbar_XnPIYyOzed,
  socialShare_gA6PfhsR4E
]