import routerOptions0 from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.10.1_@opentelemetry+api@1.6.0_@types+node@20.11.17_eslint@8.56.0_typescript@5.3.3_vite@5.2.6/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/opt/render/project/src/apps/refresh/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}